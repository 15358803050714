import React from 'react';
import { SButton } from './styles';

export const ButtonColor = {
    red: '#EF2359',
    blue: '#1458E4',
    black: '#000000',
    primary: '#1E6980'
};

const Button = ({ children, bgColor, ...props }) => {
    return (
        <SButton bgColor={ButtonColor[bgColor]} {...props}>
            {children}
        </SButton>
    );
};

export default Button;
