import React from 'react';
import { Row } from 'react-bootstrap';
import { SSectionH2Title, StyledContainer } from '../Layout/styles';
import { SCarouselItem, SCarouselWrapper } from './styles';
import LeftArrow from '../../images/svg/leftArrow.svg';
import RightArrow from '../../images/svg/rightArrow.svg';

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <RightArrow
            className={className}
            style={{
                ...style,
                position: 'absolute',
                right: '-30px',
                top: '100px',
            }}
            onClick={onClick}
        />
    );
};

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <LeftArrow
            className={className}
            style={{
                ...style,
                position: 'absolute',
                left: '-30px',
                top: '115px',
            }}
            onClick={onClick}
        />
    );
};

const SliderContainer = ({ isGapped = false, title, data = [], slidesToShow = 5 }) => {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        adaptiveHeight: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 2,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <StyledContainer fluid>
            <Row>
                <SSectionH2Title>{title}</SSectionH2Title>
                <SCarouselWrapper {...settings} isGapped={isGapped}>
                    {data?.map((item) => (
                        <SCarouselItem>{item}</SCarouselItem>
                    ))}
                </SCarouselWrapper>
            </Row>
        </StyledContainer>
    );
};

export default SliderContainer;
