import { debounce } from 'lodash';

export const calculate = debounce(
    (
        laikotarpis,
        grazosProcentas,
        pradinisInasas,
        menesineImoka, // menesineImoka gali buti menesio, ketvricio, pusmecio, metu
        setChartData,
        setVisaImoka,
        setVisasPelnas
    ) => {
        const MAX_ADMIN_TAX = 40; // maksimalus administravimo mokestis pirmiem 3 metam
        const ADMIN_TAX_AFTER = 2; // administravimo mokestis po 3 metu
        const MAX_COLLECTED_AMOUNT_TAX = 0.0033333; // % mokestis nuo sukauptos sumos kai virsija 1,45eur 12 menesiu
        const MIN_COLLECTED_AMOUNT_TAX = 17.4; // minimalus eur mokestis nuo sukauptos sumos 12 menesiu

        let chartData = [];
        let metai = [];
        let uzdarbis = 0;
        let investuotaSuma = [];
        let investicineGraza = [];
        let palukanos = 1 + grazosProcentas / 100;
        const metinesImokos = menesineImoka * 12;
        let turtoValdymoMokestis = 0;
        let sukauptosSumosMokestis = 0;

        const calculateFirstYearsTax = (metai) => {
            const tax = laikotarpis * 2;

            if (tax > 40 && metai < 4) return MAX_ADMIN_TAX / 100; // jeigu mokesciai yra daugiau negu 40%, visada isliks 40%
            return ADMIN_TAX_AFTER / 100; // kitu atveju, pvx.: jei 10 metų, 2x10= 20%
        };

        const calculateTaxForCollectedAmount = (sukauptaSuma) => {
            if (
                sukauptaSuma * MAX_COLLECTED_AMOUNT_TAX <
                MIN_COLLECTED_AMOUNT_TAX
            )
                return MIN_COLLECTED_AMOUNT_TAX;
            return sukauptaSuma * MAX_COLLECTED_AMOUNT_TAX;
        };

        const calculateTotalAmount = (tvm, ssm) => {
            return (metinesImokos - tvm - ssm + uzdarbis) * palukanos;
        };

        // laikotarpis = laikotarpis * 12; // laikotarpis paverciamas menesius
        for (let i = 1; i <= laikotarpis; i++) {
            if (i < 4) {
                // pirmieji 3 metai

                if (i === 1) {
                    // pirmasi
                    turtoValdymoMokestis =
                        metinesImokos * calculateFirstYearsTax(i);

                    // pirma menesi skaiciuojami 2% nuo pradinio inaso
                    const pirmoMenesioMokestis =
                        pradinisInasas * (ADMIN_TAX_AFTER / 100);

                    // graza skaiciuojama iskart nuo 980 (jeigu 1000 pradinis)
                    uzdarbis =
                        (metinesImokos -
                            turtoValdymoMokestis +
                            (pradinisInasas - pirmoMenesioMokestis)) *
                        palukanos;
                } else {
                    turtoValdymoMokestis =
                        metinesImokos * calculateFirstYearsTax(i);

                    sukauptosSumosMokestis =
                        calculateTaxForCollectedAmount(uzdarbis);

                    uzdarbis = calculateTotalAmount(
                        turtoValdymoMokestis,
                        sukauptosSumosMokestis
                    );
                }
            } else {
                turtoValdymoMokestis = metinesImokos * calculateFirstYearsTax(i);

                sukauptosSumosMokestis =
                    calculateTaxForCollectedAmount(uzdarbis);

                uzdarbis = calculateTotalAmount(
                    turtoValdymoMokestis,
                    sukauptosSumosMokestis
                );
            }

            // sudedama kiekvienas menesis, nes pirma menesi yra papildomu mokesciu nuo pradinio inaso

            metai[i] = new Date().getFullYear() + i;
            investuotaSuma[i] = metinesImokos * i + pradinisInasas;
            investicineGraza[i] = uzdarbis;
            // console.log(
            //     `po ${i} metu ${uzdarbis} likutis, visas inasas = ${investuotaSuma[i]}, mokesciai = ${turtoValdymoMokestis} + ${sukauptosSumosMokestis}`
            // );
            chartData.push([metai[i], investuotaSuma[i], investicineGraza[i]]);
        }

        let visa_imoka = investuotaSuma.slice(-1)[0];
        let visas_pelnas = investicineGraza.slice(-1)[0];

        setChartData(chartData);
        setVisaImoka(visa_imoka.toFixed(2));
        setVisasPelnas(visas_pelnas.toFixed(2));
    },
    500
);
