import styled from 'styled-components';

export const SDescription = styled.p`
    text-align: center;
`;

export const SSliderLabel = styled.label``;
export const SSlider = styled.input.attrs({
    type: 'range',
})`
    width: 100%;
    height: 2px;
    border-radius: 0px;
    background-color: #000;
    outline: none;
    opacity: 1;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    padding: 0;
    outline: none;
    border: none;
    margin-top: 15px;
    -webkit-appearance: none;
    appearance: none;

    &:focus {
        outline: none;
    }
    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: #000000;
        border-radius: 5px;
        border: 0px solid #000000;
    }
    &::-webkit-slider-thumb {
        box-shadow: 0px 0px 0px #000000;
        border: 0px solid #000000;
        height: 17px;
        width: 17px;
        border-radius: 21px;
        background: #1E6980!important;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -7.5px;
    }
    &:focus::-webkit-slider-runnable-track {
        background: #000000;
    }
    &::-moz-range-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: #000000;
        border-radius: 5px;
        border: 0px solid #000000;
    }
    &::-moz-range-thumb {
        box-shadow: 0px 0px 0px #000000;
        border: 0px solid #000000;
        height: 17px;
        width: 17px;
        border-radius: 21px;
        background: #1E6980!important;
        cursor: pointer;
    }
    &::-ms-track {
        width: 100%;
        height: 2px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }
    &::-ms-fill-lower {
        background: #000000;
        border: 0px solid #000000;
        border-radius: 10px;
        box-shadow: 0px 0px 0px #000000;
    }
    &::-ms-fill-upper {
        background: #000000;
        border: 0px solid #000000;
        border-radius: 10px;
        box-shadow: 0px 0px 0px #000000;
    }
    &::-ms-thumb {
        margin-top: 1px;
        box-shadow: 0px 0px 0px #000000;
        border: 0px solid #000000;
        height: 17px;
        width: 17px;
        border-radius: 21px;
        background: #1E6980!important;
        cursor: pointer;
    }
    &:focus::-ms-fill-lower {
        background: #000000;
    }
    &:focus::-ms-fill-upper {
        background: #000000;
    }
`;

export const SNumberInput = styled.input.attrs({
    type: 'number',
    className: 'form-control',
})`
    -webkit-appearance: none;
    border-radius: 0;
    border: none;
    -webkit-box-shadow: 0px 3px 22px 0px rgb(200 200 200 / 50%);
    -moz-box-shadow: 0px 3px 22px 0px rgba(200, 200, 200, 0.5);
    box-shadow: 0px 3px 22px 0px rgb(200 200 200 / 50%);
    resize: none;
`;

export const SLegendItem = styled.div`
    z-index: 99;
    text-align: center;
    font-size: 14px;
    margin-top: 45px;
    color: #000;
`;

export const SLegendText = styled.span`
    margin: 0 5px;
`;

export const SAmount = styled.span`
    font-size: 14px;
    color: #000;
`;
export const SBlackLine = styled.span`
    width: 28px;
    height: 2px;
    background-color: #000;
    display: inline-block;
    margin-bottom: 4px;
`;
export const SGoldLine = styled.span`
    width: 28px;
    height: 2px;
    background-color: #1E6980;
    display: inline-block;
    margin-bottom: 4px;
    margin-left: 18px;
`;
