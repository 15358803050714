import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Logo from '../../components/Logo';
import Newsletter from '../../components/Newsletter';
import SocialLinks from '../../components/SocialLinks';
import SliderContainer from '../SliderContainer';
import {
    SBorderedCol,
    SFirstRow,
    SSecondRow,
    SFooterTitle,
    SFooterNavLink,
    SFooterContactLine,
} from './styles';

const SSlideWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
    width: 250px;
    height: 250px;
`;

const Footer = () => {
    return (
        <>
            <StaticQuery
                query={graphql`
                    query {
                        allWpPartneris {
                            edges {
                                node {
                                    title
                                    featuredImage {
                                        node {
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}
                render={(data) => (
                    <SliderContainer
                        title="Mūsų partneriai"
                        data={data.allWpPartneris.edges.map((item) => {
                            const image = item.node.featuredImage
                                ? getImage(
                                      item.node.featuredImage.node.localFile
                                  )
                                : null;

                            return (
                                <SSlideWrapper>
                                    <GatsbyImage
                                        image={image}
                                        alt={item.node.title}
                                    />
                                </SSlideWrapper>
                            );
                        })}
                    />
                )}
            />

            <Container fluid style={{ padding: '0 25px', marginBottom: 80 }}>
                <SFirstRow>
                    <SBorderedCol sm={12} md={6} lg={3} isLogo={true}>
                        <Logo />
                    </SBorderedCol>
                    <SBorderedCol sm={12} md={6} lg={3}>
                        <div className="d-flex flex-column">
                            <SFooterTitle>Navigacija</SFooterTitle>
                            <SFooterNavLink to="/">Namai</SFooterNavLink>
                            <SFooterNavLink to="/paslaugos">
                                Paslaugos
                            </SFooterNavLink>
                            <SFooterNavLink to="/naujienos">
                                Naujienos
                            </SFooterNavLink>
                            <SFooterNavLink to="/apie-mus">
                                Apie mus
                            </SFooterNavLink>
                        </div>
                    </SBorderedCol>
                    <SBorderedCol sm={12} md={6} lg={3}>
                        <div className="d-flex flex-column">
                            <SFooterTitle>Info</SFooterTitle>
                            <SFooterNavLink to="/dazniausiai-uzduodami-klausimai">
                                D.U.K.
                            </SFooterNavLink>
                            <SFooterNavLink to="/privatumo-politika">
                                Privatumo Politika
                            </SFooterNavLink>
                            <SFooterNavLink to="/taisykles">
                                Taisyklės
                            </SFooterNavLink>
                            <SFooterNavLink to="/svarbu-zinoti">
                                Svarbu Žinoti
                            </SFooterNavLink>
                        </div>
                    </SBorderedCol>
                    <SBorderedCol sm={12} md={6} lg={3}>
                        <div className="d-flex flex-column">
                            <SFooterTitle>Kontaktai</SFooterTitle>
                            <SFooterContactLine>
                                <strong>Tel:</strong>{' '}
                                <a href="tel:+37063640444">+370 636 40444</a>
                            </SFooterContactLine>
                            <SFooterContactLine>
                                <strong>Adresas:</strong> Taikos pr. 88A
                            </SFooterContactLine>
                            <SFooterContactLine>
                                <strong>El. paštas:</strong>
                                <a href="mailto:info@fsa.lt"> info@fsa.lt</a>
                            </SFooterContactLine>
                        </div>
                    </SBorderedCol>
                </SFirstRow>
                <SSecondRow>
                    <SBorderedCol sm={12} md={6}>
                        <Newsletter />
                    </SBorderedCol>
                    <SBorderedCol sm={12} md={6} isLogo>
                        <SocialLinks />
                    </SBorderedCol>
                </SSecondRow>
            </Container>
        </>
    );
};

export default Footer;
