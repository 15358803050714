import React, { useEffect, useRef, useState } from 'react';
import { Alert, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import {
    SDescription,
    SSliderLabel,
    SSlider,
    SNumberInput,
    SLegendItem,
    SBlackLine,
    SGoldLine,
    SAmount,
    SLegendText,
} from './styles';
import { Chart } from 'react-google-charts';
import { debounce } from 'lodash';
import { calculate } from './calculate';
import ConsultationForm from '../ConsultationForm';
import Button from '../Button';

const RadioButton = ({ label, value, onChange, checked }) => (
    <Form.Check
        inline
        checked={checked}
        type="radio"
        label={label}
        id={`periodiskumas-${label}`}
        value={value}
        onChange={onChange}
    />
);

const RangeSlider = ({
    label,
    range,
    onChange,
    value,
    step = 1,
    children = null,
}) => (
    <Row>
        <Col sm={12}>
            <SSliderLabel>{label}</SSliderLabel>
        </Col>
        <Col md={9}>
            {children}
            <SSlider
                min={range.min}
                max={range.max}
                value={value}
                onChange={(e) => onChange(parseInt(e.target.value))}
                step={step}
                id="slider"
            />
        </Col>
        <Col md={3}>
            <SNumberInput
                placeholder={label}
                value={value}
                onChange={(e) => onChange(parseInt(e.target.value))}
                min={range.min}
                max={range.max}
            />
        </Col>
    </Row>
);

const CalculatorModal = ({ show, handleClose }) => {
    const [amzius, setAmzius] = useState(30);
    const [laikotarpis, setLaikotarpis] = useState(40);
    const [pradinisInas, setPradinisInas] = useState(1000);
    const [menesineImoka, setMenesineImoka] = useState(35);
    const [graza, setGraza] = useState(6);
    const [visaImoka, setVisaImoka] = useState(0);
    const [visasPelnas, setVisasPelnas] = useState(0);
    const [chartData, setChartData] = useState([]);
    const [mokTipas, setMokTipas] = useState('menesinis');
    const [errorMessage, setErrorMessage] = useState();
    const oldMokTipas = useRef();

    const [monthlyRange, setMonthlyRange] = useState({ min: 210, max: 630 });

    const [contactFormVisible, setContactFormVisible] = useState(false);

    const updateAmzius = (val) => {
        val = parseInt(val);
        setAmzius(val);
        if (val + laikotarpis > 70) {
            setLaikotarpis(70 - val);
        }
    };

    const updateLaikotarpis = (val) => {
        val = parseInt(val);
        setLaikotarpis(val);
        if (amzius + val > 70) {
            setAmzius(70 - val);
        }
    };

    const getMonthlyPrice = () => {
        if (mokTipas === 'ketvirtis') {
            return menesineImoka / 3;
        }
        if (mokTipas === 'pusmetis') {
            return menesineImoka / 6;
        }
        if (mokTipas === 'metinis') {
            return menesineImoka / 12;
        }
        return menesineImoka;
    };

    useEffect(() => {
        if (mokTipas === 'menesinis') {
            setMonthlyRange({ min: 35, max: 105 });
            if (oldMokTipas.current === 'metinis') {
                setMenesineImoka(menesineImoka / 12);
            } else if (oldMokTipas.current === 'pusmetis') {
                setMenesineImoka(menesineImoka / 6);
            } else if (oldMokTipas.current === 'ketvirtis') {
                setMenesineImoka(menesineImoka / 3);
            } else {
                setMenesineImoka(menesineImoka);
            }
        }
        if (mokTipas === 'ketvirtis') {
            setMonthlyRange({ min: 105, max: 315 });
            if (oldMokTipas.current === 'metinis') {
                setMenesineImoka(menesineImoka / 4);
            } else if (oldMokTipas.current === 'pusmetis') {
                setMenesineImoka(menesineImoka / 2);
            } else if (oldMokTipas.current === 'menesinis') {
                setMenesineImoka(menesineImoka * 3);
            } else {
                setMenesineImoka(menesineImoka);
            }
        }
        if (mokTipas === 'pusmetis') {
            setMonthlyRange({ min: 210, max: 630 });
            if (oldMokTipas.current === 'metinis') {
                setMenesineImoka(menesineImoka / 2);
            } else if (oldMokTipas.current === 'ketvirtis') {
                setMenesineImoka(menesineImoka * 2);
            } else if (oldMokTipas.current === 'menesinis') {
                setMenesineImoka(menesineImoka * 6);
            } else {
                setMenesineImoka(menesineImoka);
            }
        }
        if (mokTipas === 'metinis') {
            setMonthlyRange({ min: 420, max: 1260 });
            if (oldMokTipas.current === 'pusmetis') {
                setMenesineImoka(menesineImoka * 2);
            } else if (oldMokTipas.current === 'ketvirtis') {
                setMenesineImoka(menesineImoka * 4);
            } else if (oldMokTipas.current === 'menesinis') {
                setMenesineImoka(menesineImoka * 12);
            } else {
                setMenesineImoka(menesineImoka);
            }
        }
    }, [mokTipas]);

    useEffect(() => {
        const monPrice = getMonthlyPrice();
        if (
            laikotarpis &&
            graza &&
            pradinisInas &&
            monPrice &&
            amzius
        ) {
            setErrorMessage(null);
            calculate(
                laikotarpis,
                graza,
                pradinisInas,
                monPrice,
                setChartData,
                setVisaImoka,
                setVisasPelnas
            );
        } else {
            setErrorMessage('Visi laukeliai yra privalomi');
        }
    }, [amzius, laikotarpis, pradinisInas, menesineImoka, graza, mokTipas]);

    useEffect(() => {
        return () => {
            setContactFormVisible(false);
        };
    }, []);
    

    return (
        <Modal show={show} onHide={() => handleClose()} size="lg">
            <Modal.Header closeButton>
                {/* <Modal.Title>Modal heading</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {errorMessage && (
                        <Alert variant="danger">{errorMessage}</Alert>
                    )}
                    {contactFormVisible ? (
                        <ConsultationForm
                            isCalculator={true}
                            title="Susisieksime su jumis kaip galėdami greičiau"
                            data={{
                                amzius,
                                laikotarpis,
                                pradinisInasas: pradinisInas,
                                graza,
                                menesineImoka,
                                mokTipas,
                                visaImoka,
                                visasPelnas,
                            }}
                        />
                    ) : (
                        <>
                            <RangeSlider
                                label="Amžius"
                                range={{ min: 0, max: 60, default: amzius }}
                                onChange={updateAmzius}
                                value={amzius}
                            />
                            <RangeSlider
                                label="Laikotarpis"
                                range={{
                                    min: 10,
                                    max: 70,
                                    default: laikotarpis,
                                }}
                                onChange={updateLaikotarpis}
                                value={laikotarpis}
                            />
                            <RangeSlider
                                label="Pradinis įnašas"
                                range={{
                                    min: 200,
                                    max: 50000,
                                    default: pradinisInas,
                                }}
                                onChange={setPradinisInas}
                                value={pradinisInas}
                            />
                            <RangeSlider
                                label="Įmokų periodiškumas"
                                range={{
                                    min: monthlyRange.min,
                                    max: monthlyRange.max,
                                    default: menesineImoka,
                                }}
                                value={menesineImoka}
                                step={5}
                                onChange={setMenesineImoka}
                            >
                                <RadioButton
                                    label="Mėnesinis"
                                    value="menesinis"
                                    onChange={() => {
                                        oldMokTipas.current = mokTipas;
                                        setMokTipas('menesinis');
                                    }}
                                    checked={mokTipas === 'menesinis'}
                                />
                                <RadioButton
                                    label="Ketvirtis"
                                    value="ketvirtis"
                                    onChange={() => {
                                        oldMokTipas.current = mokTipas;
                                        setMokTipas('ketvirtis');
                                    }}
                                    checked={mokTipas === 'ketvirtis'}
                                />
                                <RadioButton
                                    label="Pusmetis"
                                    value="pusmetis"
                                    onChange={() => {
                                        oldMokTipas.current = mokTipas;
                                        setMokTipas('pusmetis');
                                    }}
                                    checked={mokTipas === 'pusmetis'}
                                />
                                <RadioButton
                                    label="Metinis"
                                    value="metinis"
                                    onChange={() => {
                                        oldMokTipas.current = mokTipas;
                                        setMokTipas('metinis');
                                    }}
                                    checked={mokTipas === 'metinis'}
                                />
                            </RangeSlider>
                            <RangeSlider
                                label="Graža %"
                                range={{ min: 0, max: 50, default: graza }}
                                onChange={setGraza}
                                step={0.1}
                                value={graza}
                            />

                            <Col
                                className="d-flex justify-content-center aling-items-center"
                                xs={12}
                            >
                                <SLegendItem>
                                    <SBlackLine></SBlackLine>
                                    <SLegendText>Investuota suma</SLegendText>
                                    <SAmount style={{ fontWeight: 'bold' }}>
                                        {visaImoka}
                                    </SAmount>
                                </SLegendItem>
                                <SLegendItem>
                                    <SGoldLine></SGoldLine>
                                    <SLegendText>Investicinė grąža</SLegendText>
                                    <SAmount style={{ fontWeight: 'bold' }}>
                                        {visasPelnas}
                                    </SAmount>
                                </SLegendItem>
                            </Col>
                            <Chart
                                chartType="LineChart"
                                data={[
                                    [
                                        'Metai',
                                        'Investuota suma €',
                                        'Investicinė grąža €',
                                    ],
                                    ...chartData,
                                ]}
                                width="100%"
                                height="400px"
                                options={{
                                    title: '',
                                    chartArea: {
                                        left: '20%',
                                        top: '15%',
                                        bottom: '15%',
                                        height: '70%',
                                        width: '75%',
                                    },
                                    tooltip: { isHtml: true, trigger: 'both' },
                                    legend: {
                                        position: 'none',
                                        alignment: 'left',
                                    },
                                    width: '100%',
                                    height: '100%',
                                    hAxis: {
                                        titleTextStyle: {
                                            color: '#000',
                                            fontName: 'Raleway',
                                            fontSize: 16,
                                            bold: true,
                                            italic: false,
                                        },
                                        format: '#',
                                        title: 'Metai',
                                    },
                                    vAxis: {
                                        titleTextStyle: {
                                            color: '#000',
                                            fontName: 'Raleway',
                                            fontSize: 16,
                                            bold: true,
                                            italic: false,
                                        },
                                        title: 'Suma',
                                    },
                                    colors: ['#000', '#1E6980'],
                                }}
                                legendToggle
                            />
                        </>
                    )}
                </Container>
            </Modal.Body>
            {!contactFormVisible && (
                <Modal.Footer className="d-flex justify-content-center">
                    <SDescription>
                        Pateikti skaičiavimai yra tik preliminarūs ir turėtų
                        būti vertinami tik kaip pavyzdys.
                    </SDescription>

                    <Button
                        bgColor="primary"
                        onClick={() => setContactFormVisible(true)}
                    >
                        Susisiekite
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default CalculatorModal;
