import styled from 'styled-components';

export const SWrapper = styled.div`
    display: flex;
    flex-direction: column;

    padding: 48px;
    border: 1px solid ${(props) => props.theme.color.grey_900};

    @media (max-width: 768px) {
        padding: 32px;
    }
`;

export const STitle = styled.h3`
    margin-bottom: 56px;
    font-weight: ${(props) => props.theme.fontWeight.medium};
`;

export const SLabel = styled.label`
    margin-bottom: 8px;
    font-weight: ${(props) => props.theme.fontWeight.medium};
    font-size: ${(props) => props.theme.fontSize.regular};
`;

export const SPrivacyLabel = styled.label`
margin-left: 10px;
    font-weight: ${(props) => props.theme.fontWeight.regular};
    font-size: ${(props) => props.theme.fontSize.regular};
    color: ${(props) => props.theme.color.grey_400};
`;

export const SInputGroup = styled.div.attrs({
    className: 'row',
})`
    display: flex;
    margin-bottom: 40px;

    @media (max-width: 425px) {
        > div:not(:last-child) {
            margin-bottom: 40px;
        }
    }
`;

export const SCheckbox = styled.input.attrs({ type: 'checkbox' })`
    width: 20px;
    height: 20px;
    border: 1px solid ${(props) => props.theme.color.grey_900};

   :before {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        border: 1px solid ${(props) => props.theme.color.grey_900};
        background: #fff;
    }
    :after {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        border: 1px solid ${(props) => props.theme.color.grey_900};
        background: #fff;
    }
`;
