import React, { useState } from 'react';
import Button from '../../components/Button';
import CalculatorModal from '../../components/CalculatorModal';
import {
    SContainer,
    Bars,
    SBrand,
    HeaderLinks,
    NavList,
    NavListItem,
} from './styles';
import Logo from '../../components/Logo';
import Sidebar from './sidebar';
import { Link } from 'gatsby';

const routes = [
    {
        path: '/',
        label: 'Namai',
    },
    {
        path: '/apie-mus',
        label: 'Apie mus',
    },
    {
        path: '/paslaugos',
        label: 'Paslaugos',
    },
    {
        path: '/karjera',
        label: 'Karjera',
    },
    {
        path: '/komanda',
        label: 'Komanda',
    },
    {
        path: '/naujienos',
        label: 'Naujienos',
    },
];

const Links = ({ setMenuState }) => {
    return (
        <>
            <NavList>
                {routes.map((route, index) => (
                    <NavListItem key={index}>
                        <Link
                            activeStyle={{
                                color: '#1E6980',
                            }}
                            to={route.path}
                            onClick={() => setMenuState(false)}
                        >
                            {route.label}
                        </Link>
                    </NavListItem>
                ))}
            </NavList>
        </>
    );
};

const Header = () => {
    const [show, setShow] = useState(false);
    const [menuState, setMenuState] = useState(false);
    const [setOpen] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <SContainer fluid className="mt-3 mb-5">
                <SBrand to="/">
                    <Logo />
                </SBrand>
                <HeaderLinks>
                    <Links />
                    <Button
                        className="ms-lg-2"
                        onClick={() => handleShow()}
                        bgColor="primary"
                    >
                        Investavimo skaičiuoklė
                    </Button>
                </HeaderLinks>
                <Bars
                    className="hovered"
                    isOpen={menuState}
                    setOpen={setOpen}
                    onClick={() => setMenuState(!menuState)}
                    color={'#000000'}
                    whileTap={{ scale: 0.9 }}
                />
                <Sidebar
                    menuState={menuState}
                    links={
                        <>
                            <Links setMenuState={setMenuState} />
                            <Button
                                className="ms-lg-2"
                                onClick={() => handleShow()}
                                bgColor="primary"
                            >
                                Investavimo skaičiuoklė
                            </Button>
                        </>
                    }
                />
            </SContainer>
            <CalculatorModal handleClose={handleClose} show={show} />
        </>
    );
};

export default Header;
