import React from 'react';
import styled from 'styled-components';
import FSALogo from '../../images/svg/logo.svg';

const SWrapper = styled.div`
    svg {
        height: 56px;
    }
`;

const Logo = () => {
    return (
        <SWrapper>
            <FSALogo />
        </SWrapper>
    );
};

export default Logo;
