import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '../../images/svg/facebook.svg';
import InstagramIcon from '../../images/svg/instagram.svg';
import LinkedInIcon from '../../images/svg/linkedin.svg';

const SWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    a:not(:last-child) {
        margin-right: 20px;
    }

    a > svg {
        path {
            &:hover {
                fill: ${(props) => props.theme.color.primary};
            }
        }
    }
`;

const SocialLinks = () => {
    return (
        <SWrapper>
            <a href="https://www.instagram.com/">
                <InstagramIcon width="22px" height="22px" />
            </a>
            <a href="https://www.facebook.com/">
                <FacebookIcon width="22px" height="22px" />
            </a>
            <a href="https://www.linkedin.com/">
                <LinkedInIcon width="22px" height="22px" />
            </a>
        </SWrapper>
    );
};

export default SocialLinks;
