import { Container } from 'react-bootstrap';
import styled, { createGlobalStyle, css } from 'styled-components';
import '../../fonts/style.css';

export const DefaultTheme = {
    shadow: '0px 3px 6px rgba(0, 0, 0, 0.15)',

    color: {
        white: '#ffffff',
        grey_25: '#f2f2f2',
        grey_50: '#e5e5e5',
        grey_100: '#d9d9d9',
        grey_200: '#bfbfbf',
        grey_300: '#a6a6a6',
        grey_400: '#8c8c8c',
        grey_500: '#737373',
        grey_600: '#595959',
        grey_700: '#404040',
        grey_800: '#262626',
        grey_900: '#151515',
        black: '#000000',
        blue: '#1458E4',
        red: '#EF2359',
        primary: '#288CAC'
    },
    breakpoints: {
        xs: '0',
        mobile: '425',
        landscape: '768',
        laptop: '1024',
        desktop: '1440',
        hd: '2560',
    },
    fontSize: {
        small: '14px',
        regular: '16px',
        large: '18px',
        title: '56px',
        h1: '48px',
        h2: '40px',
        h3: '32px',
        h4: '24px',
        h5: '20px',
        h6: '16px',
    },
    lineHeight: {
        title: '72px',
        h1: '64px',
        h2: '56px',
        h3: '48px',
        h4: '32px',
        h5: '28px',
        h6: '24px',
        large: '32px',
        regular: '24px',
        small: '20px',
    },
    fontWeight: {
        light: '300',
        regular: '400',
        medium: '600',
        bold: '700',
    },
};

const clearfix = {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
};

const headingClearfix = {};

export const GlobalStyle = createGlobalStyle`

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    overflow-y: auto;
  }

  * {
  font-family: Gilroy!important;
  }
  
  
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${(props) => props.theme.color.grey_900};
    font-family: Gilroy!important;
    font-size: ${(props) => props.theme.fontSize.regular};
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
    font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  }
  p {
    ${clearfix};
    color: ${(props) => props.theme.color.grey_600};
    font-size: ${(props) => props.theme.fontSize.regular};
    line-height: ${(props) => props.theme.lineHeight.regular};
  }
  span {
    ${clearfix};
    color: ${(props) => props.theme.color.grey_600};
    font-size: ${(props) => props.theme.fontSize.regular};
    line-height: ${(props) => props.theme.lineHeight.regular};
  }
  h1 {
    ${clearfix};
    font-size: ${(props) => props.theme.fontSize.h1};
    line-height: ${(props) => props.theme.lineHeight.h1};
    color: ${(props) => props.theme.color.grey_900};
    font-weight: ${(props) => props.theme.fontWeight.medium};
  }
  h2 {
    ${clearfix};
    font-size: ${(props) => props.theme.fontSize.h2};
    line-height: ${(props) => props.theme.lineHeight.h2};
    color: ${(props) => props.theme.color.grey_900};
    font-weight: ${(props) => props.theme.fontWeight.medium};
  }
  h3 {
    ${clearfix};
    font-size: ${(props) => props.theme.fontSize.h3};
    line-height: ${(props) => props.theme.lineHeight.h3};
    color: ${(props) => props.theme.color.grey_900};
    font-weight: ${(props) => props.theme.fontWeight.medium};
  }
  h4 {
    ${clearfix};
    font-size: ${(props) => props.theme.fontSize.h4};
    line-height: ${(props) => props.theme.lineHeight.h4};
    color: ${(props) => props.theme.color.grey_900};
    font-weight: ${(props) => props.theme.fontWeight.medium};
  }
  h5 {
    ${clearfix};
    font-size: ${(props) => props.theme.fontSize.h5};
    line-height: ${(props) => props.theme.lineHeight.h5};
    color: ${(props) => props.theme.color.grey_900};
    font-weight: ${(props) => props.theme.fontWeight.medium};
  }
  h6 {
    ${clearfix};
    font-size: ${(props) => props.theme.fontSize.h6};
    line-height: ${(props) => props.theme.lineHeight.h6};
    color: ${(props) => props.theme.color.grey_900};
    font-weight: ${(props) => props.theme.fontWeight.medium};
  }

  a{
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    text-decoration: none;
    color: ${(props) => props.theme.color.grey_900};
  }
  a:active,
  a:hover {
    outline-width: 0;
    text-decoration: underline;
    color: ${(props) => props.theme.color.grey_900};
  }
  
  input {
    :placeholder {
      color: ${(props) => props.theme.color.black};
      font-size: ${(props) => props.theme.fontSize.regular};
      opacity: 0.6;
    }
  }
  
    div[class*="col"] {
      position: relative !important;
    }
  
  @media (min-width: 2560px){
  .container {
      max-width: 1272px;
  }}
  
  .container-fluid {
        max-width: 1272px;
    }
  `;

export const SWrapper = styled.div`
    /* padding: 48px 108px; */
`;

export const SInput = styled.input`
    padding: 6px 8px;
    width: 100%;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.color.grey_900};
    outline: none;

    ::placeholder {
        color: ${(props) => props.theme.color.grey_200};
        font-size: ${(props) => props.theme.fontSize.regular};
    }

    &:focus, &:active {
        outline: none;
        border-bottom: 1px solid ${(props) => props.theme.color.primary};
    }
`;

export const SSectionH2Title = styled.h2`
    margin-bottom: 56px;
    padding: 0;
`;

export const SPageH1Title = styled.h1`
    margin-bottom: 56px;
    padding: 0;
`;

export const borderedCSS = css`
    border-bottom: 1px solid ${(props) => props.theme.color.grey_900};
    border-right: 1px solid ${(props) => props.theme.color.grey_900};
    border-left: 1px solid ${(props) => props.theme.color.grey_900};

    > div:not(:last-child) {
        border-right: 1px solid ${(props) => props.theme.color.grey_900};
    }

    @media (max-width: 768px) {
        border-right: 0;
        border-bottom: 0;

        > div:last-child {
            border-right: 1px solid ${(props) => props.theme.color.grey_900};
        }
        > div {
            border-bottom: 1px solid ${(props) => props.theme.color.grey_900};
        }
    }
`;

export const StyledContainer = styled(Container)`
    margin-bottom: 120px;
    padding: 0 25px;

    @media (max-width: 1024px) {
        margin-bottom: 80px;
    }

    @media (max-width: 768px) {
        margin-bottom: 40px;
    }
`;