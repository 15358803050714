import React from 'react';
import { SInput } from '../../containers/Layout/styles';
import { STitle, SButton, SDiv} from './styles';

const Newsletter = () => {
    return (
        <div className="d-flex flex-column justify-content-start">
            <STitle>Pamatykite visas naujienas pirmieji</STitle>
            <SDiv>
                <SInput type="email" placeholder="Įveskite savo el. paštą" />
                <SButton bgColor="primary">Prenumeruoti</SButton>
            </SDiv>
        </div>
    );
};

export default Newsletter;
