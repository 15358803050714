import styled from 'styled-components';
import { SInput } from '../../containers/Layout/styles';

export const MainDropdownInput = styled(SInput)``;

export const MainDropdown = styled.div`
    min-width: 420px;

    @media (max-width: 420px) {
        min-width: 320px;
    }
    
    @media (max-width: 320px) {
        min-width: 270px;
    }
`;

export const DropdownMenu = styled.div`
    display: none;
    float: left;
    min-width: 320px;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;

    @media (max-width: 420px) {
        min-width: 210px;
    }

    &.show {
        display: block;
    }
`;

export const CategoryButton = styled.div`
    cursor: pointer;
    padding: 12px 0;
`;

export const DropdownItem = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    clear: both;
    padding: 12px 0;
    margin-bottom: 0.125rem;
`;

export const SCheckbox = styled.input`
    flex: 1;
    width: 24px;
    height: 24px;
    accent-color: ${({ theme }) => theme.color.primary};

    &:checked {
        background-color: ${({ theme }) => theme.color.primary};
        border-color: ${({ theme }) => theme.color.grey900};
    }

    &:hover {
        background-color: ${({ theme }) => theme.color.primary};
    }
`;

export const SLabel = styled.label`
    flex: 2;    
`;

export const LinkButton = styled.button`
    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.color.primary};
    background-color: transparent;
    border: none;
    text-decoration: none;

    &:hover {
        color: ${({ theme }) => theme.color.primary};
        text-decoration: underline;
    }

    &:focus {
        color: ${({ theme }) => theme.color.primary};
        outline: none;
        box-shadow: none;
    }

    &:active {
        outline: none;
        color: ${({ theme }) => theme.color.primary};
        text-decoration: underline;
    }
`;
