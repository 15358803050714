import styled from 'styled-components';
import { Link } from 'gatsby';
import { Container, Navbar } from 'react-bootstrap';
import { Hamburger } from './hamburger';

export const Bars = styled(Hamburger)`
  display: none;
  cursor: pointer;
  z-index: 2;

  @media (max-width: 1199px) {
    display: block;
  }
`;

export const HeaderLinks = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1199px) {
        display: none;
    }
`;


export const SBrand = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 56px;
`;

export const SContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;


export const NavList = styled.ul`
    display: flex;
    margin: 0;
    padding: 0;

    @media screen and (max-width: ${(props) =>
            props.theme.breakpoints.landscape}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const NavListItem = styled.li`
    text-decoration: none;
    list-style: none;

    a {
        color: ${(props) => props.theme.color.grey_900};
        font-size: ${(props) => props.theme.fontSize.regular};
        line-height: ${(props) => props.theme.lineHeight.regular};
        font-weight: ${(props) => props.theme.fontWeight.medium};
        padding: 0 20px;

        @media (max-width: 1199px) {
            padding: 0 20px;
        }
        @media (max-width: 768px) {
            padding: 0;
            margin-bottom: 10px;
        }
    }

    @media (max-width: 769px) {
        margin-bottom: 24px;

        a {
            position: relative;
            z-index: 1;
            font-weight: 600;
            text-decoration: none;
            transition: 100ms;
            font-size: 24px;
            line-height: 32px;

            :hover {
                color: ${(props) => props.theme.primary};
                box-shadow: 0px 1.6px ${(props) => props.theme.primary};
            }
        }
    }
`;