import { Link } from 'gatsby';
import { Col, Row } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import { borderedCSS } from '../Layout/styles';

export const SFirstRow = styled(Row)`
    border-top: 1px solid ${(props) => props.theme.color.grey_900};
    ${borderedCSS}
`;

export const SSecondRow = styled(Row)`
    ${borderedCSS}
`;

export const SBorderedCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 78px 50px !important;

    @media (max-width: 768px) {
        padding: 80px 50px !important;
        justify-content: center;
        align-items: ${(props) => (props.isLogo ? 'center' : 'start')};
    }
`;

export const SFooterTitle = styled.span`
    font-size: ${(props) => props.theme.fontSize.small};
    color: ${(props) => props.theme.color.grey_400};
    margin-bottom: 24px;
`;

export const SFooterNavLink = styled(Link)`
    color: ${(props) => props.theme.color.grey_900};
    font-size: ${(props) => props.theme.fontSize.regular};
    line-height: ${(props) => props.theme.lineHeight.regular};
    margin-bottom: 8px;
`;

export const SFooterContactLine = styled.span`
    font-size: ${(props) => props.theme.fontSize.regular};
    color: ${(props) => props.theme.color.grey_900};
    margin-bottom: 8px;

    a,
    a:hover {
        color: ${(props) => props.theme.color.grey_900};
    }
`;
