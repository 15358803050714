import React, { useMemo, useRef, useState } from 'react';
import {
    DropdownMenu,
    CategoryButton,
    DropdownItem,
    SCheckbox,
    MainDropdown,
    MainDropdownInput,
    LinkButton,
    SLabel,
} from './styles.js'; // Assuming you put the CSS in the same directory
import CaretR from '../../images/svg/CaretR.svg';
import Caret from '../../images/svg/Caret.svg';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useStaticQuery, graphql } from 'gatsby';

const SubDropdown = ({
    options,
    category,
    selectedOptions,
    setSelectedOptions,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (option) => {
        const newSelectedOptions = { ...selectedOptions };
        // key is category name, value is array of selected options
        if (newSelectedOptions[category]?.includes(option)) {
            newSelectedOptions[category] = newSelectedOptions[category].filter(
                (item) => item !== option
            );
        } else {
            newSelectedOptions[category] = [
                ...(newSelectedOptions[category] || []),
                option,
            ];
        }

        setSelectedOptions(newSelectedOptions);
    };

    return (
        <div className="dropdown d-flex flex-column">
            <CategoryButton onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? <Caret /> : <CaretR />} {category}
            </CategoryButton>
            {isOpen && (
                <DropdownMenu className={isOpen ? 'show' : ''}>
                    {options.map((option, index) => (
                        <DropdownItem key={index}>
                            <SCheckbox
                                type="checkbox"
                                id={`${option}-${index}`}
                                checked={selectedOptions[category]?.includes(
                                    option
                                )}
                                onChange={() => handleSelect(option)}
                            />
                            <SLabel for={`${option}-${index}`}>{option}</SLabel>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            )}
        </div>
    );
};

const MultiSelectDropdown = ({ setSelectedOptions, selectedOptions }) => {
    const [isOpen, setIsOpen] = useState(false);

    const clickRef = useRef();
    useClickOutside(clickRef, () => {
        setIsOpen(false);
    });

    const { allWpDominanciosPaslaugos } = useStaticQuery(
        graphql`
            query {
                allWpDominanciosPaslaugos {
                    nodes {
                        categories {
                            nodes {
                                name
                            }
                        }
                        title
                    }
                }
            }
        `
    );

    // category name can be the same, I need to store it under same key, and add all options to the array under that key
    const categoryAndOptions = useMemo(() => {
        const categories = allWpDominanciosPaslaugos.nodes;

        let categoriesArray = {};
        categories.map((c) => {
            const category = c.categories?.nodes[0]?.name;
            const title = c.title;

            console.log(category, title);
            if (category && title) {
                // add category and options to the array, merge same names of categories and add options to the array

                categoriesArray = {
                    ...categoriesArray,
                    [category]: [...(categoriesArray[category] || []), title],
                };
            }
        });
        return categoriesArray;
    }, []);

    return (
        <div className="dropdown" ref={clickRef}>
            <MainDropdownInput
                className=""
                type="text"
                onClick={() => setIsOpen(!isOpen)}
                placeholder="Jus dominančios paslaugos"
                value={Object.values(selectedOptions).flat().join(', ')}
                onChange={() => setSelectedOptions([])}
            />
            {isOpen && (
                <MainDropdown className="dropdown-menu show px-4">
                    {Object.keys(categoryAndOptions).map((category) => {
                        const options = categoryAndOptions[category];
                        if (category && options)
                            return (
                                <SubDropdown
                                    key={category}
                                    category={category}
                                    options={options}
                                    selectedOptions={selectedOptions}
                                    setSelectedOptions={setSelectedOptions}
                                />
                            );
                    })}
                    <div className="d-flex justify-content-between">
                        <>
                            {/* <LinkButton
                                className="btn btn-link"
                                type="button"
                                onClick={() => {
                                    const newSelectedOptions = [];
                                    Object.keys(categoryAndOptions).forEach(
                                        (category) =>
                                            (newSelectedOptions[category] =
                                                categoryAndOptions[
                                                    category
                                                ].flat())
                                    );
                                    setSelectedOptions(newSelectedOptions);
                                }}
                            >
                                Pasirinkti visus
                            </LinkButton> */}
                            <LinkButton
                                className="btn btn-link"
                                type="button"
                                onClick={() => setSelectedOptions({})}
                            >
                                Išvalyti
                            </LinkButton>
                            <LinkButton
                                className="btn btn-link"
                                type="button"
                                onClick={() => setIsOpen(false)}
                            >
                                Pateikti
                            </LinkButton>
                        </>
                    </div>
                </MainDropdown>
            )}
        </div>
    );
};

export default MultiSelectDropdown;
