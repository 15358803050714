import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

const Sidebar = ({ menuState, links }) => {
  return (
    <>
      <AnimatePresence>
        {menuState && (
          <Overlay
            initial={{ y: '-100%' }}
            exit={{ y: '-100%' }}
            animate={{ y: menuState ? 0 : '-100%' }}
            transition={{ duration: 0.4, ease: [0.6, 0.05, -0.01, 0.9] }}
          >
            <SidebarContainer>
              <SidebarContent>
                <SidebarLinks>
                  {links}
                </SidebarLinks>
              </SidebarContent>
            </SidebarContainer>
          </Overlay>
        )}
      </AnimatePresence>
    </>
  );
};

export default Sidebar;

const Overlay = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0rem 1rem;
  position: relative;
  background: #ffffff;
  height: 100vh;
  overflow: hidden;
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
`;


const SidebarLinks = styled.div`
  ul {
    display: flex;
    flex-direction: column;
  }

  li {
    margin-left: 0;
  }
`;
