import Layout from './src/containers/Layout';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
import { ThemeProvider } from 'styled-components';
import { DefaultTheme } from './src/containers/Layout/styles';


// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
    return (
        <ThemeProvider theme={DefaultTheme}>
            <Layout {...props}>{element}</Layout>
        </ThemeProvider>
    );
};
