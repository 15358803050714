import styled from 'styled-components';
import Button from '../Button';

export const STitle = styled.span`
    font-size: ${(props) => props.theme.fontSize.regular};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    color: ${(props) => props.theme.color.grey_900};
    line-height: ${(props) => props.theme.lineHeight.regular};
    margin-bottom: 8px;
`;

export const SButton = styled(Button)`
    margin-left: 16px;

    @media (max-width: 768px) {
        margin-left: 0;
        margin-top: 8px;
    }
`;

export const SDiv = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;

        button {
            width: 100%;
        }
    }
`;
