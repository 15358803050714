import styled, { css } from 'styled-components';

const outlinedCSS = css`
    background-color: transparent;
    color: ${(props) => props.theme.color.grey_900};
    border: 1px solid ${(props) => props.theme.color.grey_900};
    border-radius: 4px;
`;

const disabledCSS = css`
    color: ${(props) => props.theme.color.grey_100};
    border: 1px solid ${(props) => props.theme.color.grey_100};
`;

export const SButton = styled.button`
    background-color: ${(props) => props.bgColor};
    border: none;
    border-radius: 4px;
    color: ${(props) => props.theme.color.white};
    font-size: ${(props) => props.theme.fontSize.regular};
    font-weight: ${(props) => props.theme.fontWeight.medium};
    padding: 12px 24px;
    width: fit-content;

    ${(props) => props.outlined && outlinedCSS}
    ${(props) => props.disabled && disabledCSS}

    &:hover {
        cursor: pointer;
        background-color: #1e6880;
    }
`;
