import React, { useState } from 'react';
import {
    SWrapper,
    STitle,
    SInputGroup,
    SLabel,
    SCheckbox,
    SPrivacyLabel,
} from './styles';
import { SInput } from '../../containers/Layout/styles';
import Button from '../Button';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import MultiSelectDropdown from '../MultiSelectDropdown';

const ConsultationForm = ({
    data = null,
    title = 'Registracija į konsultaciją',
    isCalculator = false,
}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [formState, setFormState] = useState({
        name: '',
        email: '',
        phone: '',
        privacy: false,
        formMessage: '',
        isErrorMessage: false,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const { name, email, phone, privacy } = formState;

    const handeFormSubmit = (e) => {
        e.preventDefault();

        const subject = `Naujas laiškas nuo ${name} (${email}) <${email}>`;
        console.log(selectedOptions);
        const emailBody = `
            <p><strong>Vardas:</strong> ${name}</p>
            <p><strong>El. paštas:</strong> ${email}</p>
            <p><strong>Telefono numeris:</strong> ${phone}</p>
            <p><strong>Paslaugos:</strong> ${
                isCalculator ? 'Skaičiuoklė' : Object.keys(selectedOptions).map((key) => `<p><strong>${key}:</strong> ${selectedOptions[key].join(', ')}</p>`)
            }</p>
            
            ${
                isCalculator && data
                    ? `
                <p><strong>Amžius:</strong> ${data.amzius}</p>
                <p><strong>Laikotarpis:</strong> ${data.laikotarpis}</p>
                <p><strong>Pradinis įnašas:</strong> ${data.pradinisInasas}</p>
                <p><strong>Grąža:</strong> ${data.graza}</p>
                <p><strong>Mėnesinė įmoka:</strong> ${data.menesineImoka}</p>
                <p><strong>Mokėjimo tipas:</strong> ${data.mokTipas}</p>
                <p><strong>Visa įmoka:</strong> ${data.visaImoka}</p>
                <p><strong>Visas pelnas:</strong> ${data.visasPelnas}</p>
            `
                    : ''
            }
        `;

        const body = {
            contact_email: email,
            receiver_email: 'borneikaaivara@gmail.com',
            contact_name: name,
            subject: subject,
            message: emailBody,
        };
        axios
            .post('https://new.fsa.lt/wp-json/contact/v1/send', body)
            .then(() => {
                setFormState((prevState) => ({
                    ...prevState,
                    formMessage: 'Laiškas sėkmingai išsiųstas',
                    isErrorMessage: false,
                }));
            })
            .catch(() => {
                setFormState((prevState) => ({
                    ...prevState,
                    formMessage: 'Laiško išsiųsti nepavyko',
                    isErrorMessage: true,
                }));
            });
    };

    return (
        <SWrapper>
            <STitle>{title}</STitle>
            <form onSubmit={handeFormSubmit}>
                <SInputGroup>
                    <Col xs={12} md={6}>
                        <SLabel htmlFor="name">Jūsų vardas *</SLabel>
                        <SInput
                            onChange={handleChange}
                            name="name"
                            type="text"
                            id="name"
                            placeholder="Vardenis Pavardenis"
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <SLabel htmlFor="email">Jūsų el. paštas *</SLabel>
                        <SInput
                            onChange={handleChange}
                            value={email}
                            name="email"
                            type="email"
                            id="email"
                            placeholder="vardenis@email.com"
                        />
                    </Col>
                </SInputGroup>
                <SInputGroup>
                    <Col>
                        <SLabel htmlFor="tel">Jūsų telefono numeris *</SLabel>
                        <SInput
                            value={phone}
                            onChange={handleChange}
                            name="phone"
                            type="tel"
                            id="tel"
                            placeholder="+370 000 00000"
                        />
                    </Col>
                </SInputGroup>
                {!isCalculator && (
                    <SInputGroup>
                        <Col>
                            <SLabel htmlFor="paslaugos">
                                Jus dominančios paslaugos *
                            </SLabel>
                            <MultiSelectDropdown setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} />

                        </Col>
                    </SInputGroup>
                )}
                <div className="d-flex align-items-center mb-5">
                    <SCheckbox
                        type="checkbox"
                        id="privacy-policy"
                        onChange={handleCheckboxChange}
                        name="privacy"
                        checked={privacy}
                    />
                    <SPrivacyLabel htmlFor="privacy-policy">
                        Pažymiu, kad sutinku su privatumo politika
                    </SPrivacyLabel>
                </div>
                {formState.formMessage && (
                    <div
                        className={`text-center ${
                            formState.isErrorMessage
                                ? 'text-danger'
                                : 'text-success'
                        }`}
                    >
                        {formState.formMessage}
                    </div>
                )}
                <Button
                    bgColor={'black'}
                    outlined
                    type="submit"
                    disabled={!formState.privacy}
                >
                    Registruokitės
                </Button>
            </form>
        </SWrapper>
    );
};

export default ConsultationForm;
