import React from 'react';
import Footer from '../Footer';
import Header from '../Header';

import { DefaultTheme, GlobalStyle, SWrapper } from './styles';

const Layout = ({ children }) => {
    return (
        <SWrapper>
            <GlobalStyle theme={DefaultTheme} />
            <Header />
            {children}
            <Footer />
        </SWrapper>
    );
};

export default Layout;
