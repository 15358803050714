import styled, { css } from 'styled-components';
import Slider from 'react-slick';

const borderRight = css`
    border-right: 1px solid ${(props) => props.theme.color.grey_900};
`;

const bordersAround = css`
    border-top: 1px solid ${(props) => props.theme.color.grey_900};
    border-bottom: 1px solid ${(props) => props.theme.color.grey_900};
    border-left: 1px solid ${(props) => props.theme.color.grey_900};
`;

const gaps = css`
    padding: 0 10px;
    box-sizing: border-box;
`;

const fullBorderForGaps = css`
    border: 1px solid ${(props) => props.theme.color.grey_900};
`;

export const SCarouselWrapper = styled(Slider)`
    padding: 0;

    .slick-slide {
        ${(props) => !props.isGapped && bordersAround};
        ${(props) => props.isGapped && gaps};
    }
    .slick-slide > div {
        ${(props) => props.isGapped && fullBorderForGaps};
    }
    .slick-track:last-child {
        ${(props) => !props.isGapped && borderRight};
    }
    .slick-list {
        ${(props) => !props.isGapped && borderRight};
    }
`;

export const SCarouselItem = styled.div`
    display: flex !important;
    justify-content: center;
`;
