import { motion } from 'framer-motion';
import React from 'react';

export const Hamburger = ({
    isOpen = false,
    transition = null,
    lineProps = null,
    color = '#ffffff',
    ...props
  }) => {
      const hamburgerButtonStyle = {
          transformOrigin: 'center',
          border: 'none',
          background: 'none',
          outline: 'none',
      };

      const hamburgerLineStyle = {
          width: '28px',
          height: '3px',
          display: 'block',
          background: color,
          margin: '6px',
      };

      const variant = isOpen ? 'opened' : 'closed';
      const hamburgerIconTop = {
          closed: {
              rotate: 0,
              y: 0,
          },
          opened: {
              rotate: 45,
              y: 10,
          },
      };

      const hamburgerIconCenter = {
          closed: {
              opacity: 1,
              transition: { duration: 0 },
          },
          opened: {
              opacity: 0,
              transition: { duration: 0 },
          },
      };

      const hamburgerIconBottom = {
          closed: {
              rotate: 0,
              y: 0,
          },
          opened: {
              rotate: -45,
              y: -8,
          },
      };

      lineProps = {
          animate: variant,
          ...lineProps,
      };

      return (
          <motion.div style={hamburgerButtonStyle} {...props}>
              <motion.span
                  style={hamburgerLineStyle}
                  variants={hamburgerIconTop}
                  {...lineProps}
              />
              <motion.span
                  style={hamburgerLineStyle}
                  variants={hamburgerIconCenter}
                  {...lineProps}
              />
              <motion.span
                  style={hamburgerLineStyle}
                  variants={hamburgerIconBottom}
                  {...lineProps}
              />
          </motion.div>
      );
  };